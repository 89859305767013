import React, { useRef } from "react"
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import HTMLReactParser from "html-react-parser";

// Configs
import multiContentSlider from "../../../utilities/multiContentSlider";
import {ImageModule} from '../../../modules/Image_Module'

// Styles
import './About.scss';

// Components
import NavigationArrow from "../../../components/NavigationArrow";


const About = ({details, tab_id}) => {

    const sliderRef = useRef();

    const settings = {
        ...multiContentSlider,
        nextArrow: (
            <NavigationArrow
                type="left"
                sliderRef={ sliderRef }
            />
        ),
        prevArrow: (
            <NavigationArrow
                type="right"
                sliderRef={ sliderRef }
            />
        ),
    };

    return (
        <div className="about-wrapper" id={tab_id}>
            <Container>
                <Row>
                    <Col lg="10" className="content_sec">
                        {details?.Content && HTMLReactParser(details?.Content)}
                    </Col>
                </Row>
                
                {details?.Slider_Images?.length > 0 && 
                    <div className="multi-content-slider-wrapper">
                        <Slider
                            { ...settings }
                            ref={ sliderRef }
                        >
                            { details.Slider_Images.map( ( sliderImage, sliderIndex ) => {

                                let processedImages = JSON.stringify({});
                                if (details?.imagetransforms?.Slider_Images_Transforms) {
                                    processedImages = details?.imagetransforms?.Slider_Images_Transforms;
                                }

                                return (
                                    <div
                                        key={ sliderIndex }
                                        className="slider-image-wrapper"
                                    >
                                        <div className="slider-image">
                                            <ImageModule ImageSrc={sliderImage} imagetransforms={processedImages} renderer="srcSet" imagename="area-guides.Slider_Images.slider_section" strapi_id={details.id} />
                                        </div>
                                    </div>
                                )
                            } ) }
                        </Slider>
                    </div>
                }
                
            </Container>
        </div >
    )
}

export default About
