import React, { useRef } from "react"
import { Col, Container, Row } from "react-bootstrap";

// Styles
import './AreaGuides.scss';

// Components
import SectionWrapper from "../SectionWrapper";
import SectionHeader from "../SectionHeader";
import SingleAreaCard from "../SingleAreaCard";

import {GetAreaGuides, GetAreaGuidesLimit} from "../../queries/common_use_query";

const AreaGuides = ( props ) => {
    const { loading, error, data } = props?.DetailLayout ? GetAreaGuidesLimit()  : GetAreaGuides();

    const layout = { lg: 4, sm: 6, xs: 12 }
    let responsiveShowHide = true;

    const sectionHeaderData = {
        Title: props.Title,
        Link: props.ViewAll ? props.ViewAllText : {}
    }

    return (
        <SectionWrapper
            className={ `layout-01-wrapper area-guides-wrapper` }
            id={'areaGuides'}
        >
            <Container>
                <Row>
                    <Col>
                        <div className="layout-01-header-section">
                            <SectionHeader
                                sectionHeaderData={ sectionHeaderData }
                            />
                        </div>
                    </Col>
                </Row>
                { data && data?.areaGuides?.length > 0 && (
                    <Row className="multi-content-cards-wrapper">
                        {
                            data?.areaGuides?.map( ( cardsDataItem, cardsDataIndex ) => {

                                const getClassName = ( cardsDataIndex ) => {
                                    if ( cardsDataIndex > 0 && cardsDataIndex < 2 ) {
                                        return 'hide-on-tab-and-mobile';
                                    } else if ( cardsDataIndex > 0 ) {
                                        return 'hide-on-mobile';
                                    }
                                    return '';
                                }

                                return (
                                    <Col
                                        key={ cardsDataIndex }
                                        { ...layout }
                                        className={ responsiveShowHide && getClassName( cardsDataIndex ) }
                                    >
                                        <SingleAreaCard item={cardsDataItem} />
                                    </Col>
                                )
                            } )
                        }
                    </Row>
                ) }
            </Container>
        </SectionWrapper>
    )
}

export default AreaGuides