import * as React from 'react';
import { Container } from "react-bootstrap";

// Components
import SectionWrapper from "../../../components/SectionWrapper";
import SectionHeader from "../../../components/SectionHeader";
import PropertyCards from "../../../components/PropertyCards";
import OurBuildingProperties from "../../../components/PropertyCards/OurBuildingProperties";

import {CustomLinks} from "../../../components/common/menuUtils";

// Styles
import './FeaturedProperties.scss';

const FeaturedProperties = ( { Module, tab_id, classNames, type } ) => {

    const sectionHeaderData = {
        Title: Module.Title,
        SubTitle: Module.Content,
        Link: {
            FullText: 'View All Properties',
            ShortText: 'View All',
            Href: Module.View_All_Link
        }
    }

    //console.log("Module", Module.Property_Module);

    return (
        <SectionWrapper className={`featured-properties-wrapper ${classNames}`} id={tab_id} >
            <Container>
                <div className='property-cards-header-section'>
                    <SectionHeader
                        sectionHeaderData={ sectionHeaderData }
                    />
                </div>

                <div className="sub-section-wrapper property-cards-body-section">
                    {type === "our_building" ?
                        <OurBuildingProperties
                        propertyCardsData={ Module.Our_Buildings_Module }
                        layout={ {
                            lg: 4,
                            sm: 6,
                            xs: 12,
                        } }
                        responsiveShowHide
                        />

                    :
                        <PropertyCards
                            propertyCardsData={ Module.Property_Module }
                            layout={ {
                                lg: 4,
                                sm: 6,
                                xs: 12,
                            } }
                            responsiveShowHide
                        />
                    }
                    
                </div>
            </Container>
        </SectionWrapper>
    );
};

export default FeaturedProperties;
