import React, { useRef } from "react"
import { Col, Container, Row, Card } from "react-bootstrap";
import HTMLReactParser from "html-react-parser";
import Slider from "react-slick";

// Styles
import './AreaGuides.scss';

// Components
import multiContentSlider from "../../utilities/multiContentSlider";
import NavigationArrow from "../NavigationArrow";

import SectionWrapper from "../SectionWrapper";
import SectionHeader from "../SectionHeader";
import {ImageModule} from '../../modules/Image_Module'

const AreaGuideDetails = ( props ) => {
    const sliderRef = useRef();

    const sectionHeaderData = {
        Title: props.Module.Title,
    }

    const settings = {
        ...multiContentSlider,
        infinite: props?.Module?.Restaurant_List?.length > 3 ? true : false,
        nextArrow: (
            <NavigationArrow
                type="left"
                sliderRef={ sliderRef }
                //hover="false"
            />
        ),
        prevArrow: (
            <NavigationArrow
                type="right"
                sliderRef={ sliderRef }
                //hover="false"
            />
        ),
    };

    return (
        <SectionWrapper
            className={ `layout-01-wrapper area-guides-wrapper ${props.Module.Section_BG_Color}` }
            id={props.Module.Title.toLowerCase().replace(/ /g,'')}
        >
            <Container>
                <Row>
                    <Col>
                        <div className="layout-01-header-section">
                            <SectionHeader
                                sectionHeaderData={ sectionHeaderData }
                            />
                        </div>
                    </Col>
                </Row>

                { props.Module.Content && (
                    <Row>
                        <Col lg="10">
                            <div className="layout-01-content-section">
                                {props.Module.Content && HTMLReactParser(props.Module.Content)}
                            </div>
                        </Col>
                    </Row>
                ) }

                { props.Module.Restaurant_List.length > 0 && (
                    <div className="multi-content-slider-wrapper areaGuideDetails">
                        <Slider
                            { ...settings }
                            ref={ sliderRef }
                        >
                            { props.Module.Restaurant_List.map( ( sliderDataItem, sliderDataIndex ) => {
                                return (
                                    <div
                                        key={ sliderDataIndex }
                                        className="slider-image-wrapper"
                                    >
                                        <div className="single-area-card-wrapper">
                                            <Card>
                                                <div className="card-container-image">
                                                    <ImageModule ImageSrc={ sliderDataItem.Image } classNames={"card-img-top"} />
                                                </div>
                                                <Card.Body>
                                                    <Card.Title>
                                                        { sliderDataItem.Name }
                                                    </Card.Title>
                                                    <Card.Text className="card-content">
                                                        { sliderDataItem.Content }
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                )
                            } ) }
                        </Slider>
                    </div>
                ) }
            </Container>
        </SectionWrapper>
    )
}

export default AreaGuideDetails