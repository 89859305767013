import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import imageConfig from "../../../static/images/config.json"

// Styles
import "./PageBanner.scss"

// Components
import CustomButton from "../../components/CustomButton"

const PageBanner = ( { GQLPage } ) => {

    let processedImages = JSON.stringify({});
    if (GQLPage?.imagetransforms?.Banner_Image_Transforms) {
        processedImages = GQLPage?.imagetransforms?.Banner_Image_Transforms;
    }
    return (
    <>
        {GQLPage && 
            <div className="page-banner-wrapper" style={{backgroundImage:`url(${GQLPage?.Banner_Image?.url})`}}>
                {/* <ImageTransform imagesources={GQLPage?.Banner_Image?.url} renderer="bgImg" imagename="articles.Banner_Image.header" attr={{ alt: GQLPage?.Banner_Image?.alternativeText }} imagetransformresult={processedImages} id={GQLPage?.id} /> */}

                    <div className="overlay_bg"></div>

                    <div  className="banner_content">
                        <Container>                    
                            <Row>
                                <Col lg="9">
                                    <h1 className="banner-title">{ GQLPage?.Banner_Title }</h1>
                                    <p className="banner-subtitle">{ GQLPage?.Banner_Content }</p>
                                </Col>
                            </Row>
                            
                            <Row>
                                {GQLPage?.Banner_CTA_Label && 
                                <Col lg="3" sm="5">
                                    <CustomButton
                                        variant="green"
                                        value={ GQLPage?.Banner_CTA_Label }
                                        link={GQLPage?.Banner_CTA_Link}
                                    />
                                </Col>
                                }

                                {GQLPage?.Banner_CTA_Label_2 && 
                                <Col lg="3" sm="5">
                                    <CustomButton
                                        variant="outline"
                                        value={ GQLPage?.Banner_CTA_Label_2 }
                                        link={GQLPage?.Banner_CTA_Link_2}
                                    />
                                </Col>
                                }
                            </Row>
                        </Container>
                    </div>
            </div>
        }
        
    </>
    )
}

export default PageBanner
