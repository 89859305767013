import React, { useEffect, useState } from "react"
import { Row, Col } from 'react-bootstrap';

// Styles
import './PropertyCards.scss';

// Components
import {GetAPIData, Building_API} from "../common/agentAPI"
import SinglePropertyCard from '../SinglePropertyCard/BuildingCard';

const PropertyCards = ( { propertyCardsData, layout, showLabel = false, responsiveShowHide } ) => {
	const[buildList, setBuildingList] = useState([]);

	//console.log("propertyCardsData", propertyCardsData);
	useEffect(() => {
        var building_api = Building_API+"?_limit=-1";

        GetAPIData(building_api).then(async apiRes => {
            
            var building_list = [];
            if(apiRes?.success === true){
                apiRes?.results?.length > 0 && apiRes?.results.map((item) =>{
                    building_list.push(item);
                })
                setBuildingList(building_list)
            }
        });
    }, [])

	console.log("buildList", buildList);
	
	return (
		<div className="property-cards-wrapper">
			{
				buildList?.length > 0 && (
					<Row>
						{
							buildList.map( ( propertyDetails, propertyDetailsIndex ) => {
								
								const getClassName = ( propertyDetailsIndex ) => {
									if ( propertyDetailsIndex > 0 && propertyDetailsIndex < 2 ) {
										return 'hide-on-tab-and-mobile';
									} else if ( propertyDetailsIndex > 0 ) {
										return 'hide-on-mobile';
									}
									return '';
								}

								return (
									<Col
										key={ propertyDetailsIndex }
										{ ...layout }
										//className={ responsiveShowHide && getClassName( propertyDetailsIndex ) }
									>
										<SinglePropertyCard
											singlePropertyCardData={ propertyDetails.Properties || propertyDetails }
											showLabel={ showLabel }
										/>
									</Col>
								)

							} )
						}
					</Row>
				)
			}
		</div>
	);
};

export default PropertyCards;
