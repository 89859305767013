import React from "react"
import { Button } from "react-bootstrap"
import { Link } from "@reach/router";

// Styles
import "../CustomButton/CustomButton.scss"

const CustomButton = ( customButtonData ) => {

    const { value, type, variant, link, classNames, ...otherProperties } = customButtonData;

    return (
        <div
            className={`custom-button-wrapper ${classNames ? classNames : ""}`}
            { ...otherProperties }
        >
            {link ? 
                <Link to={ link } className={`btn btn-${variant}`}>
                    { value }
                </Link>
                :
                <Button type={type}
                variant={ variant }
                >
                    { value }
                </Button>
            }
            
        </div>
    )
}

export default CustomButton
