import React, { useRef } from "react"
import { Container, Row, Col, Card } from 'react-bootstrap';
import Slider from "react-slick";

// Components
import SinglePropertyCard from '../SinglePropertyCard';
import multiContentSlider from "../../utilities/multiContentSlider";
import NavigationArrow from "../NavigationArrow";

// Styles
import './PropertyCards.scss';


const PropertyCards = ( { propertyCardsData, layout, showLabel = false, responsiveShowHide } ) => {

	const sliderRef = useRef();
	const settings = {
        ...multiContentSlider,
        infinite: propertyCardsData?.length > 3 ? true : false,
        nextArrow: (
            <NavigationArrow
                type="left"
                sliderRef={ sliderRef }
                //hover="false"
            />
        ),
        prevArrow: (
            <NavigationArrow
                type="right"
                sliderRef={ sliderRef }
                //hover="false"
            />
        ),
    };

	//console.log("propertyCardsData", propertyCardsData);

	return (
		<div className="property-cards-wrapper">
			{
				propertyCardsData?.length > 0 && (

						<div className="multi-content-slider-wrapper ">
						<Slider
                            { ...settings }
                            ref={ sliderRef }
                        >
						{
							propertyCardsData.map( ( propertyDetails, propertyDetailsIndex ) => {
								
								const getClassName = ( propertyDetailsIndex ) => {
									if ( propertyDetailsIndex > 0 && propertyDetailsIndex < 2 ) {
										return 'hide-on-tab-and-mobile';
									} else if ( propertyDetailsIndex > 0 ) {
										return 'hide-on-mobile';
									}
									return '';
								}

								return (
									<div
                                        key={ propertyDetailsIndex }
                                        className="slider-image-wrapper"
                                    >
										<SinglePropertyCard
											singlePropertyCardData={ propertyDetails.Properties || propertyDetails }
											showLabel={ showLabel }
										/>
									</div>
								)

							} )
						}
						</Slider>
						</div>


				)
			}
		</div>
	);
};

export default PropertyCards;
