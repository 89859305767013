import React, { useState, useEffect } from "react"


import discoverMarylebone from "../data/discover-marylebone.json"

// Components
import {CustomLinks} from "../components/common/menuUtils"

import Layout from "../components/layout"
import BreadCrumbs from "../components/BreadCrumbs/StaticBreadcrumb"
import LoaderLogo from "../components/LoaderLogo"

import PageBanner from "../components/PageBanner"
import ScrollTabs from "../components/ScrollTabs/StaticTabs"
import About from "../layout/DiscoverMarylebone/About"
import FeaturedProperties from "../layout/Home/FeaturedProperties"
import AreaGuideDetails from "../components/AreaGuides/AreaGuideDetails"
import LocRatingMap from "../components/LocratingMap/locrating-map-details"
import AreaGuides from "../components/AreaGuides"

import {GetAreaGuideDetails} from "../queries/common_use_query";

const PropertyDetailsTemplate = (props) => {
    const { loading, error, data } = GetAreaGuideDetails(props.pageContext?.slug);
    const [ details, setGetDetails ] = useState({});

    useEffect(()=>{
        data && data.areaGuides.length > 0 && setGetDetails(data.areaGuides[0])
    },[data])
    
    // Loading logo 
    if (loading) return (
        <LoaderLogo />
    );
    // Loading logo

    //console.log("data", details)

    let mapItems = [];
    let mapItem = {};
    mapItem['lat']  = details?.Latitude      
    mapItem['lng']  = details?.Longitude
    mapItem['id']  = details?.id
    const mapMarkerImageShowFlag = 0;
    mapItems.push( mapItem );

    const headerTabs = [
        {
            name: 'About',
            id: '#about'
        },
        {
            name: 'Property',
            id: '#featuredProperties'
        },
    ];

    details && details?.Modules?.length > 0 && details?.Modules?.filter(Module => Module.__typename === "ComponentComponentAreaGuideComponent").map((Module, index) => {
        headerTabs.push({name:Module.Title, id:'#'+Module.Title.toLowerCase().replace(/ /g,'')})
    })
    
    headerTabs.push({name: 'Location',id: '#location'})

    return (
        <React.Fragment>
            {details && details.Name &&
                <Layout template="property_details" Meta_Title={details.Name} Meta_Description={details.Name}>
                    
                    <PageBanner GQLPage={{Banner_Title:details.Banner_Title, Banner_Content:details.Banner_Content, Banner_CTA_Label:details.Banner_CTA_Label, Banner_CTA_Link:details.Banner_CTA_Link, Banner_Image:details.Banner_Image}} />

                    <BreadCrumbs
                        breadCrumbsData={ 
                            [{
                                name: 'Home',
                                link: '/'
                            },
                            {
                                name: "Explore London",
                                link: "/"+CustomLinks.explore_london+"/"
                            },
                            {
                                name: details.Name,
                                // link: '#'
                            }]
                        }
                    />
                    <ScrollTabs headerTabs={ headerTabs } defaultTab={"About"}>
                        <About details={details} tab_id="about"/>

                        {details.Modules.length > 0 && details.Modules.map((Module, index) => {
                            return(
                                <>
                                    {Module.__typename === "ComponentComponentFeaturedProperties" && Module.Show_Featured_Properties && <FeaturedProperties  Module={Module} tab_id="featuredProperties" />}

                                    {Module.__typename === "ComponentComponentAreaGuideComponent" &&<AreaGuideDetails Module={Module} tab_id={Module.id} />}
                                </>
                            )
                        })
                        }

                        <div id="location">
                            <LocRatingMap data={mapItems} showTab={false} />
                        </div>
                        
                        <AreaGuides DetailLayout={true} Title="Area Guides" ViewAll={true} ViewAllText={{FullText: "View All Area Guides", ShortText:"View All", Href:"/"+CustomLinks.explore_london+"/"}}/>
                        
                    </ScrollTabs>
                </Layout>
            }
            
        </React.Fragment>
    )
}

export default PropertyDetailsTemplate