import * as React from "react"
import { Row, Col, Card } from "react-bootstrap"
import { Link } from "@reach/router";

// Styles
import "./SingleAreaCard.scss"

// Components
import CustomLink from "../CustomLink";
import {CustomLinks} from "../common/menuUtils";
import {ImageModule} from '../../modules/Image_Module'

const SingleAreaCard = ({item}) => {

    const { Name, Banner_Content, URL, Banner_Image } = item;

    var area_link = "/"+CustomLinks.explore_london+"/"+URL+"/";

    var featured_prop_filter = item.Modules.filter(item => item.__typename === "ComponentComponentFeaturedProperties")

    var featured_prop = [];
    featured_prop_filter?.length > 0 && featured_prop_filter[0].Property_Module.map(item => {
        var split_addr = item.Properties.display_address.split(',')
        var get_post_code = split_addr[split_addr.length - 2];
        get_post_code && featured_prop.push(get_post_code.trim());
    })

    let processedImages = JSON.stringify({});
    if (item?.imagetransforms?.Banner_Image_Transforms) {
        processedImages = item?.imagetransforms?.Banner_Image_Transforms;
    }
    return (
        <div className="single-area-card-wrapper">
            <Card>
                <Link to={area_link}>
                    <div className="card-container-image">
                        <ImageModule ImageSrc={Banner_Image} classNames={"card-img-top"} imagetransforms={processedImages} renderer="srcSet" imagename="area-guides.Banner_Image.header" strapi_id={item.id} />
                    </div>
                </Link>
                <Card.Body>
                    <Card.Title>
                        <Link to={area_link}>{ Name }</Link>
                    </Card.Title>
                    <Card.Text className="card-content">
                        { Banner_Content }
                    </Card.Text>
                    {featured_prop.length > 0 && 
                        <Card.Text className="card-muted-text">
                            {featured_prop.join(", ")}
                            {/* {featured_prop.map(item => item && item) } */}
                        </Card.Text>
                    }
                    <div>
                        <CustomLink
                            caretPosition="right"
                            href={ area_link }
                        >
                            { Name+" Area Guide" }
                        </CustomLink>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default SingleAreaCard
